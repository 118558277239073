/* Estilos generales para el footer */
.footer {
    background-color: black;
    color: white;
    width: 100%;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: start;
    text-align: start;
    flex-wrap: wrap;
    font-family: "Montserrat", sans-serif;
}

/* Columnas del footer */
.footer-column {
    flex: 1;
    min-width: 200px;
    padding: 10px;
}

/* Contenedor para el logo y el texto */
.footer-logo-text, 
.footer-icon-text {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

/* Tamaño y estilo para los SVG en pantallas grandes */
.footer-icon-text svg {
    width: 24px; /* Ajusta este tamaño según prefieras */
    height: 24px;
    margin-right: 10px;
}

/* Ajuste de tamaño del logo */
.footer-logo-text img {
    max-height: 30px;
    margin-right: 10px;
}

/* Ajustes para el texto */
p {
    line-height: 20px;
}

/* Estilos de responsividad */
@media (max-width: 767px) {
    .footer {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    
    .footer-column {
        text-align: center;
        margin-bottom: 10px;
    }
    
    /* Cambiamos la disposición de íconos y texto en dispositivos pequeños */
    .footer-logo-text, 
    .footer-icon-text {
        flex-direction: column;
        align-items: center;
    }

    /* Ajuste del tamaño del SVG para pantallas pequeñas */
    .footer-icon-text svg {
        width: 30px;
        height: 30px;
        margin-right: 0;
        margin-bottom: 5px;
    }

    /* Eliminamos el margen en dispositivos pequeños */
    .footer-logo-text img {
        margin-right: 0;
        margin-bottom: 5px;
    }
}
