* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    justify-content: flex-start;
    min-height: 100vh; /* Ocupa toda la altura de la ventana */
    background: linear-gradient(135deg, #000000, #262626, #740d0d, #a3a3a3);
    background-size: 400% 400%;
    animation: gradientAnimation 15s ease infinite;
    color: #ffffff;
    font-family: "Exo 2", sans-serif;
    overflow: auto;
    padding-top: 20px; /* Ajusta el padding para centrar mejor */
}

/* Imagen */
img {
    height: 200px;
}

/* Estilos de los textos */
h1 {
    font-weight: 500;
    margin: 0;
    font-size: 3rem;
    text-transform: capitalize;
}

h2 {
    font-style: italic;
    margin: 10px 0 0;
    font-size: 1.5rem;
}

/* Animación del gradiente */
@keyframes gradientAnimation {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
}

@media (max-width: 767px) {
    h1 {
        font-size: 37px;
    }
    .container{
        padding-top: 160px;
    }
    h2 {
        font-size: 23px;
    }
}

@media (max-width: 1200px) {
    h1 {
        font-size: 40px;
    }
    .container{
        padding-top: 200px;
    }
    h2 {
        font-size: 25px;
    }
}

@media (min-width: 1201px) {
    h1 {
        font-size: 50px;
    }
    .container{
        padding-top:100px;
    }
    h2 {
        font-size: 30px;
    }
}
